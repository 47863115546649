import { action, observable } from 'mobx';
import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import sessionService from '../services/session/sessionService';


class SessionStore {
  @observable currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();
  


  @action
  async getCurrentLoginInformations() {

    let result = await sessionService.getCurrentLoginInformations();
    this.currentLogin = result;

    //this.currentLogin.tenant.tenancyName = 'Company Wireless';
    //this.currentLogin.tenant.profileImageURL = 'https://files.swilab.com/devLogo.png';
    //this.currentLogin.user.userName = 'Benjamin Allen';
    //this.currentLogin.user.profileImageURL = 'https://files.swilab.com/defaultProfileImg.png';

    return result;

  }



}

export default SessionStore;
