export const DashboardItemTypes = {

    MetaText: -12,
    VideoCard: -11,
    WebContent: -10,
    Calendar: -9,
    Comments: -8,
    ProgressBar: -7,
    Statistic: -6,
    Countdown: -5,
    Image: -4,
    ImageCarousel: -3,
    Button: -2,
    DataGrid: -1,

    AreaChart: 0,
    BarChart: 1,
    BoxPlot: 2, 
    BubbleGraph: 3,
    Bullet: 4,
    CirclePacking: 5, 
    ColumnGraph: 6, 
    DonutChart: 7, 
    FunnelChart: 8, 
    Gauge: 9, 
    HeatMap: 10, 
    Histogram: 11, 
    LineChart: 12, 
    Liquid: 13, 
    MultiLineChart: 14, 
    PieChart: 15, 
    Progress: 16, 
    RadarChart: 17, 
    RadialBarChart: 18, 
    Ring: 19, 
    RoseChart: 20, 
    SankeyPlot: 21, 
    ScatterPlot: 22, 
    StockPlot: 23, 
    SunburstPlot: 24, 
    VennPlot: 25, 
    ViolinPlot: 26, 
    WaterfallChart: 27,  

    TinyAreaPlot: 101,
    TinyColumnPlot: 102,
    TinyLinePlot: 103,
    TinyLiquidPlot: 104,
    TinyProgress: 105,
    TinyRingProgress: 106,
    TinyStatistic: 107

}
