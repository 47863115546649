import { IsTenantAvaibleInput } from './dto/isTenantAvailableInput';
import { RegisterInput } from './dto/registerInput';
import  SMSVerification  from './dto/smsVerification';
import IsTenantAvaibleOutput from './dto/isTenantAvailableOutput';
import http from '../httpService';
import ForgotPasswordDto  from '../user/dto/forgotPasswordDto';

import { ContactUsInput }  from './dto/contactUsInput';
import { SignUpInput }  from './dto/signUpInput'; 

class AccountService {
  public async isTenantAvailable(isTenantAvaibleInput: IsTenantAvaibleInput): Promise<IsTenantAvaibleOutput> {
    let result = await http.post('api/services/app/Account/IsTenantAvailable', isTenantAvaibleInput);
    return result.data.result;
  }

  public async register(registerInput: RegisterInput) {
    let result = await http.post('api/services/app/Apps/Register', registerInput);
    return result.data.result;
  }

  public async contactUs(contactUsInput: ContactUsInput) { 
    let result = await http.post('api/services/app/Account/ContactUsTBTLA', contactUsInput);
    return result.data.result;
  }

  public async contactInvoice(contactUsInput: ContactUsInput) {
    let result = await http.post('api/services/app/Apps/ConfirmOrderJBF', contactUsInput);
    return result.data.result;
  }

  public async signUp(signUpInput: SignUpInput) {
    let result = await http.post('api/services/app/Apps/SignUp', signUpInput);
    return result.data.result;
  }

  public async scheduleDemo(scheduleDemoInput: ContactUsInput) {
    let result = await http.post('api/services/app/Account/ScheduleDemo', scheduleDemoInput);
    return result.data.result;
  }

  public async verifySMS(smsVerification: SMSVerification) {
    let result = await http.post('api/services/app/Apps/VerifySMSJBF', smsVerification);
    return result.data.result;
  }

  public async forgotPassword(forgotPasswordDto: ForgotPasswordDto) {
    let result = await http.post('api/services/app/Apps/ForgotPassword', forgotPasswordDto);
    return result.data.result;
  }

  public async forgotPasswordReset(forgotPasswordDto: ForgotPasswordDto) {
    let result = await http.post('api/services/app/Apps/ForgotPasswordReset', forgotPasswordDto);
    return result.data.result;
  }


}

export default new AccountService();
