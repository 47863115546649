import { action, observable } from 'mobx';
import type { MenuReturnDto } from '../services/menu/dto/menuReturnDto';
import type { MenuCreateDto } from '../services/menu/dto/menuCreateDto';
import type { MenuAskDto } from '../services/menu/dto/menuAskDto';
import { MenuEntityDto } from '../services/menu/dto/entities/menuEntityDto';
import { MenuItemDto } from '../services/menu/dto/entities/menuItemDto';
import { MenuOptionDto } from '../services/menu/dto/entities/menuOptionDto';
import { MenuRecordDto } from '../services/menu/dto/entities/menuRecordDto';
import { MenuStateDto } from '../services/menu/dto/entities/menuStateDto';
import { MenuTypeDto } from '../services/menu/dto/entities/menuTypeDto';
import menuService from '../services/menu/menuService';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import { MenuTemplateDto } from '../services/menu/dto/entities/menuTemplateDto';
import { UpdateMenuOptionList } from '../services/menu/dto/views/updateMenuOptionList';
import { UpdateMenuItemList } from '../services/menu/dto/views/updateMenuItemList';
import type { UpdateMenuStateList } from '../services/menu/dto/views/updateMenuStateList';
import type { UpdateMenuTypeList } from '../services/menu/dto/views/updateMenuTypeList';

import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import type { UpdateMenuTemplateList } from '../services/menu/dto/views/updateMenuTemplateList';
import { EntityDto } from '../services/dto/entityDto';
import type { RcFile } from 'antd/lib/upload';
import type { UploadFile } from 'antd/lib/upload/interface';
import type { EntityCommitContainer, FileUpload } from '../services/dto/fileActionResultDto';

import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import type { Data, Fields, Meta } from '@antv/s2';
import { FormGridDto } from '../components/EntityConfigs/formGridDto';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import { NoteDto } from '../services/dto/entities/noteDto';
import { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import { NotificationDto } from '../services/dto/entities/notificationDto';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import type { UserListSummaryDto } from '../services/dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import { ValueReturnDto } from '../services/dto/values/valueReturnDto';
import { isGranted } from '../lib/abpUtility';
import communicationService from '../services/communication/communicationService';
import integrationService from '../services/integrations/integrationService';
import inventoryService from '../services/inventory/inventoryService';
import paymentService from '../services/payments/paymentService';
import peopleService from '../services/people/peopleService';
import reportService from '../services/report/reportService';
import schedulingService from '../services/scheduling/schedulingService';
import teamService from '../services/team/teamService';
import workOrderService from '../services/workOrder/workOrderService';
import { TemplateRecordDto } from '../services/dto/records/templateRecordDto';
import type { PersonListSummaryDto } from '../services/dto/summaries/personListSummaryDto';
import type { TeamListSummaryDto } from '../services/dto/summaries/teamListSummaryDto';
import { GetAllRoleOutput } from '../services/role/dto/getAllRoleOutput';
import { formItemTypes } from '../components/EntityConfigs/formItemTypes';


class MenuStore {
  //--------------------------------------------------------------------------

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

  @observable entityAsk!: MenuAskDto;

  @observable entityCreate!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityEdit!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityDelete!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityReturn!: MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

  //-------------------------------------------------------------------------- 
  @observable multiEntityList!: PagedResultDto<any>;

  @observable entitySummary!: PagedResultDto<any>;
  @observable entities!: PagedResultDto<any>;
  @observable entitiesList!: PagedResultDto<any>;

  @observable entitySummaryCondensed!: PagedResultDto<any>;

  @observable entityOptionSummary!: PagedResultDto<any>;
  @observable entityOptions!: PagedResultDto<any>;
  @observable optionsList!: PagedResultDto<any>;

  @observable entityStateSummary!: PagedResultDto<any>;
  @observable entityStates!: PagedResultDto<any>;
  @observable statesList!: PagedResultDto<any>;

  @observable entityItemSummary!: PagedResultDto<any>;
  @observable entityItems!: PagedResultDto<any>;
  @observable itemsList!: PagedResultDto<any>;
  @observable itemOptionsCache!: PagedResultDto<any>;

  @observable entityTypeSummary!: PagedResultDto<any>;
  @observable entityTypes!: PagedResultDto<any>;
  @observable typesList!: PagedResultDto<any>;
  @observable typeItemsCache!: PagedResultDto<any>;
  @observable typeStatesCache!: PagedResultDto<any>;
  @observable typeItemsEdit!: PagedResultDto<any>;
  @observable typeStatesEdit!: PagedResultDto<any>;

  @observable entityTemplateSummary!: PagedResultDto<any>;
  @observable entityTemplates!: PagedResultDto<any>;
  @observable templatesList!: PagedResultDto<any>;
  @observable templateItems!: PagedResultDto<any>;
  @observable defaultTemplateRecords!: Array<TemplateRecordDto>;
  @observable templateRecordCache!: Array<TemplateRecordDto>;

  @observable defaultValues!: FormGridDto[];
  @observable valuesCache!: FormGridDto[];

  //--------------------------------------------------------------------------

  @observable defaultEntityImages!: Array<UploadFile>;
  @observable defaultEntityFiles!: Array<UploadFile>;
  @observable fileCache!: EntityCommitContainer;

  //--------------------------------------------------------------------------
  @observable entitySummaryList!: Array<any>;
  @observable templateSummaryList!: Array<any>;
  @observable typeSummaryList!: Array<any>;
  @observable fieldSummaryList!: Array<any>;
  @observable peopleSummaryList!: Array<any>;
  @observable teamSummaryList!: Array<any>;

  //--------------------------------------------------------------------------

  //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
  @observable cards_CommentData!: Array<any>;
  @observable cards_BodyData!: Array<any>;
  @observable cards_HeaderData!: Array<any>;
  @observable cards_TagData!: Array<any>;

  @observable cards_BodyConfig!: Array<any>;
  @observable cards_HeaderConfig!: Array<any>;
  @observable cards_TagConfig!: Array<any>;

  //--------------------------------------------------------------------------


  @observable profileID: number = 0;
  @observable entityID: number = 0;
  @observable entityType: number = 0;
  @observable currentState: number = 0;
  @observable defaultEntityNotes!: Array<NoteDto>;
  @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;
  @observable currentNotifications: NotificationDto[] = [];
  @observable entityNotesCache!: Array<NoteDto>;
  @observable entityNoteRecordsCache!: Array<NoteRecordDto>;
  @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

  @observable users!: PagedResultDto<UserListSummaryDto>;
  @observable defaultUserList!: Array<UserListSummaryDto>;
  @observable userListCache!: Array<UserListSummaryDto>;
  @observable userListSummary!: PagedResultDto<UserListSummaryDto>;

  @observable users_Security!: PagedResultDto<UserListSummaryDto>;
  @observable defaultUserList_Security!: Array<UserListSummaryDto>;
  @observable userListCache_Security!: Array<UserListSummaryDto>;
  @observable userListSummary_Security!: PagedResultDto<UserListSummaryDto>;

  @observable persons!: PagedResultDto<PersonListSummaryDto>;
  @observable defaultPersonList!: Array<PersonListSummaryDto>;
  @observable personListCache!: Array<PersonListSummaryDto>;
  @observable personListSummary!: PagedResultDto<PersonListSummaryDto>;

  @observable persons_Security!: PagedResultDto<PersonListSummaryDto>;
  @observable defaultPersonList_Security!: Array<PersonListSummaryDto>;
  @observable personListCache_Security!: Array<PersonListSummaryDto>;
  @observable personListSummary_Security!: PagedResultDto<PersonListSummaryDto>;

  @observable teams!: PagedResultDto<TeamListSummaryDto>;
  @observable defaultTeamList!: Array<TeamListSummaryDto>;
  @observable teamListCache!: Array<TeamListSummaryDto>;
  @observable teamListSummary!: PagedResultDto<TeamListSummaryDto>;

  @observable teams_Security!: PagedResultDto<TeamListSummaryDto>;
  @observable defaultTeamList_Security!: Array<TeamListSummaryDto>;
  @observable teamListCache_Security!: Array<TeamListSummaryDto>;
  @observable teamListSummary_Security!: PagedResultDto<TeamListSummaryDto>;

  @observable rolesSummary!: PagedResultDto<GetAllRoleOutput>;

  @observable usersearch_Mentions: string[] = [];
  @observable usersearch_Hashtags: string[] =
    ['Online',
      'Available',
      'My Team'];

  @observable personsearch_Mentions: string[] = [];
  @observable personrsearch_Hashtags: string[] =
    ['Online',
      'Available',
      'My Team'];

  @observable teamsearch_Mentions: string[] = [];
  @observable teamsearch_Hashtags: string[] =
    ['Online',
      'Available',
      'My Team'];


  //--------------------------------------------------------------------------

  @observable entitysearch_Mentions: string[] = [];
  @observable itemsearch_Mentions: string[] = [];
  @observable optionsearch_Mentions: string[] = [];
  @observable statesearch_Mentions: string[] = [];
  @observable templatesearch_Mentions: string[] = [];
  @observable typesearch_Mentions: string[] = [];

  @observable entitysearch_Hashtags: string[] = [];
  @observable itemsearch_Hashtags: string[] = [];
  @observable optionsearch_Hashtags: string[] = [];
  @observable statesearch_Hashtags: string[] =
    ['Starting Step',
      'Workflow Step',
      'End Step',
      'Terminal Step'];

  @observable templatesearch_Hashtags: string[] = [];
  @observable typesearch_Hashtags: string[] = [];

  //--------------------------------------------------------------------------
  // Set Three Unique Data Tables for Entity Data
  //--------------------------------------------------------------------------

  @observable entityCondensed_dataExportTable!: any;
  @observable entityCondensed_dataExportTableData: Data[] = [];
  @observable entityCondensed_dataExportTableTotalData: Data[] = [];
  @observable entityCondensed_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable entityCondensed_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];


  @observable entity_dataExportTable!: any;
  @observable entity_dataExportTableData: Data[] = [];
  @observable entity_dataExportTableTotalData: Data[] = [];
  @observable entity_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable entity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];


  @observable entityActivity_dataExportTable!: any;
  @observable entityActivity_dataExportTableData: Data[] = [];
  @observable entityActivity_dataExportTableTotalData: Data[] = [];
  @observable entityActivity_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'actionType', 'entityName'],
  };
  @observable entityActivity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "actionType",
      "name": "Type"
    },
    {
      "field": "actionID",
      "name": "Action ID"
    },
    {
      "field": "entityName",
      "name": "Entity"
    },
    {
      "field": "entityID",
      "name": "Entity ID"
    },
    {
      "field": "previousStateName",
      "name": "Previous State"
    },
    {
      "field": "previousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "previousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "newStateName",
      "name": "New State"
    },
    {
      "field": "newStateAction",
      "name": "New Action"
    },
    {
      "field": "newStateID",
      "name": "New State ID"
    },
    {
      "field": "itemName",
      "name": "Item"
    },
    {
      "field": "itemID",
      "name": "Item ID"
    },
    {
      "field": "itemValue",
      "name": "Item Value"
    },
    {
      "field": "notes",
      "name": "Notes"
    },
    {
      "field": "createdTime",
      "name": "Created"
    },
    {
      "field": "creatorID",
      "name": "Creator"
    },
    {
      "field": "ownerID",
      "name": "Owner"
    },
    {
      "field": "relatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "relatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "linkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "linkedEntityID",
      "name": "Linked Entity ID"
    },
  ];

  //--------------------------------------------------------------------------


  @observable template_dataExportTable!: any;
  @observable template_dataExportTableData: Data[] = [];
  @observable template_dataExportTableTotalData: Data[] = [];
  @observable template_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable template_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable type_dataExportTable!: any;
  @observable type_dataExportTableData: Data[] = [];
  @observable type_dataExportTableTotalData: Data[] = [];
  @observable type_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable type_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable state_dataExportTable!: any;
  @observable state_dataExportTableData: Data[] = [];
  @observable state_dataExportTableTotalData: Data[] = [];
  @observable state_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id',
      'name',
      'title',
      'forwardText',
      'revertText',
      'allowSkipTo',
      'allowRevert',
      'nextActionOnly',
      'lastActionOnly',
      'noActions'],
  };
  @observable state_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
    {
      "field": "displayType",
      "name": "Display Type"
    },
    {
      "field": "forwardText",
      "name": "Forward Text"
    },
    {
      "field": "revertText",
      "name": "Revert Text"
    },
    {
      "field": "restricted",
      "name": "Restricted"
    },
    {
      "field": "allowSkipTo",
      "name": "Allow Skip"
    },
    {
      "field": "allowRevert",
      "name": "Allow Revert"
    },
    {
      "field": "nextActionOnly",
      "name": "Next Only"
    },
    {
      "field": "lastActionOnly",
      "name": "Last Only"
    },
    {
      "field": "noActions",
      "name": "No Actions"
    },

  ];

  @observable item_dataExportTable!: any;
  @observable item_dataExportTableData: Data[] = [];
  @observable item_dataExportTableTotalData: Data[] = [];
  @observable item_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable item_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable option_dataExportTable!: any;
  @observable option_dataExportTableData: Data[] = [];
  @observable option_dataExportTableTotalData: Data[] = [];
  @observable option_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id',
      'name',
      'title',
      'optionType',
      'boundState',
      'boundRole',
      'boundPerson',
      'boundTeam',
      'inputType'],
  };

  @observable option_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];


  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await menuService.uploadImage(formData);
    result.uid = result.assetID.toString();
    this.fileCache.fileList.push(result.assetID);

    return result;
  }

  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await menuService.uploadImage(formData);

    this.profileID = result.assetID;
    return result;
  }

  @action
  async getProfileImage(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await menuService.getImage(recordID);
      return result.url;
    }
  }

  @action //gets files for initial list
  async getFiles() {

    try {

      let result = await menuService.getFiles(this.entityID, this.entityType);

      //Add to Default Loads
      result.map((x: FileUpload) => {

        if (x.type === 'image/png') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpeg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else {
          this.defaultEntityFiles.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }

        return this.fileCache.fileList.push(x.assetID);
      })
    }
    catch { }
    return;
  }

  @action // 
  async removeFileFromList(file: UploadFile) {
    this.fileCache.fileList = this.fileCache.fileList.filter((x: number) => x.toString() !== file.uid);
    return;
  }

  //--------------------------------------------------------------------------

  @action //gets Users for initial list
  async getUsers(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    try {

      var userAsk = {
        entityType: RelatedEntityTypes.User,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Menu,
        listFilter: false,
        availableFilter: false,
        relatedEntityType: relatedEntityType
      }

      let result = await menuService.getSummaryView(userAsk);


      this.users = {
        totalCount: 0,
        items: []
      }

      this.userListSummary = {
        totalCount: 0,
        items: []
      }

      this.defaultUserList = [];
      this.userListCache = [];
      this.usersearch_Mentions = [];

      result.users.map((x: any) => {
        this.users.items.push(x);
        this.users.totalCount = this.users.totalCount + 1;
        this.usersearch_Mentions.push(x.firstName + ' ' + x.lastName);


        if (mapRecords === true) {

          this.entityReturn.records.map((y: any) => {
            if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
              this.userListSummary.items.push(x);
              this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
              this.defaultUserList.push(x);
              this.userListCache.push(x);
            }

          });
        }
      });



    }
    catch { }
    return;
  }
  @action //Add Notes to Temp List
  async addUser(user: UserListSummaryDto, isSecurity: boolean) {

    try {

      let userCache = user;

      if (isSecurity === true) {
        this.userListCache_Security.push(userCache);

        if (userCache.profileImageID < 0) {
          let url = await this.getProfileImage(userCache.profileImageID);
          if (url === '_') {
            userCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            userCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.userListSummary_Security.items.push(userCache);
        this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount + 1;


      }
      else {
        this.userListCache.push(userCache);

        if (userCache.profileImageID < 0) {
          let url = await this.getProfileImage(userCache.profileImageID);
          if (url === '_') {
            userCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            userCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.userListSummary.items.push(userCache);
        this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
      }

    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeUser(recordID: number, isSecurity: boolean) {

    try {

      if (isSecurity === true) {
        this.userListCache_Security = this.userListCache_Security.filter((x: UserListSummaryDto) => x.userID !== recordID);
        this.userListSummary_Security.items = this.userListSummary_Security.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
        this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount - 1;
      }
      else {
        this.userListCache = this.userListCache.filter((x: UserListSummaryDto) => x.userID !== recordID);
        this.userListSummary.items = this.userListSummary.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
        this.userListSummary.totalCount = this.userListSummary.totalCount - 1;
      }

    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncUsers(entityID: number, entityRecords: any[]) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {

      this.entityCreate.relatedEntityType = RelatedEntityTypes.Record;
      this.entityDelete.relatedEntityType = RelatedEntityTypes.Record;
      var userAddList = this.userListCache;
      //Parse default against temp for Deletes 
      this.defaultUserList.map((x: UserListSummaryDto) => {

        var containsUser = false;
        this.userListCache.map((y: UserListSummaryDto) => {
          if (x.userID === y.userID) { containsUser = true; }
        })

        if (containsUser === false) {

          entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.User && z.LinkedEntityID === x.userID) {
              this.entityDelete.records.push({
                id: z.id,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.User,
                linkedEntityID: x.userID
              })
            }
          })
        }
        else
        {
          userAddList = userAddList.filter((a: UserListSummaryDto) => a.userID !== x.userID);
        }

      })

      await menuService.delete(this.entityDelete);

      userAddList.map((x: any) => {
        if (x.id < 0) {
          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Record,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.User,
            linkedEntityID: x.userID
          })

        }
      });

      await menuService.create(this.entityCreate);

    }
    catch { }
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getPeople(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    try {

     var teamAsk = {
       entityType: RelatedEntityTypes.People,
       entityID: entityID,
       filter: '',
       moduleType: itemTypes.Menu,
       listFilter: false,
       availableFilter: false,
       relatedEntityType: relatedEntityType
     }

     let result = await menuService.getSummaryView(teamAsk);

      this.persons = {
        totalCount: 0,
        items: []
      }

      this.personListSummary = {
        totalCount: 0,
        items: []
      }

      this.defaultPersonList = [];
      this.personListCache = [];
      this.personsearch_Mentions = [];

      result.people.map((x: any) => {
        this.persons.items.push(x);
        this.persons.totalCount = this.persons.totalCount + 1;
        this.personsearch_Mentions.push(x.name);

        if (mapRecords === true) {


          this.entityReturn.records.map((y: any) => {
            if (y.linkedEntityType === RelatedEntityTypes.People && y.linkedEntityID === x.personID && y.relatedEntityType === RelatedEntityTypes.Record) {
              this.personListSummary.items.push(x);
              this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
              this.defaultPersonList.push(x);
              this.personListCache.push(x);
            }

          });

        }
      });

    }
    catch { }
    return;
  }

  @action
  async addPerson(person: PersonListSummaryDto, isSecurity: boolean) {

    try {

      let personCache = person;

      if (isSecurity === true) {
        this.personListCache_Security.push(personCache);

        if (personCache.profileImageID < 0) {
          let url = await this.getProfileImage(personCache.profileImageID);
          if (url === '_') {
            personCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            personCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.personListSummary_Security.items.push(personCache);
        this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount + 1;
      }
      else {
        this.personListCache.push(personCache);

        if (personCache.profileImageID < 0) {
          let url = await this.getProfileImage(personCache.profileImageID);
          if (url === '_') {
            personCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            personCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.personListSummary.items.push(personCache);
        this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
      }



    }
    catch { }
    return;
  }

  @action
  async removePerson(recordID: number, isSecurity: boolean) {

    try {

      if (isSecurity === true) {
        this.personListCache_Security = this.personListCache_Security.filter((x: PersonListSummaryDto) => x.personID !== recordID);
        this.personListSummary_Security.items = this.personListSummary_Security.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
        this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount - 1;
      }
      else {
        this.personListCache = this.personListCache.filter((x: PersonListSummaryDto) => x.personID !== recordID);
        this.personListSummary.items = this.personListSummary.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
        this.personListSummary.totalCount = this.personListSummary.totalCount - 1;
      }
    }
    catch { }
    return;
  }

  @action
  async syncPeople(entityID: number, entityRecords: any[]) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {

      this.entityCreate.relatedEntityType = RelatedEntityTypes.Record;
      this.entityDelete.relatedEntityType = RelatedEntityTypes.Record;
      var personAddList = this.personListCache;

      //Parse default against temp for Deletes 
      this.defaultPersonList.map((x: PersonListSummaryDto) => {

        var containsPerson = false;
        this.personListCache.map((y: PersonListSummaryDto) => {
          if (x.personID === y.personID) { containsPerson = true; }
        })

        if (containsPerson === false) {

          entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.People && z.LinkedEntityID === x.personID) {
              this.entityDelete.records.push({
                id: z.id,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.People,
                linkedEntityID: x.personID
              })
            }
          })
        }
        else
        {
          personAddList = personAddList.filter((a: PersonListSummaryDto) => a.personID !== x.personID);
        }

      })

      await menuService.delete(this.entityDelete);

      personAddList.map((x: any) => {
        if (x.id < 0) {
          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Record,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.People,
            linkedEntityID: x.personID
          })

        }
      });

      await menuService.create(this.entityCreate);

    }
    catch { }
    return;
  }

  //--------------------------------------------------------------------------

  @action //gets Teams for initial list
  async getTeams(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    try {

      var teamAsk = {
        entityType: RelatedEntityTypes.Teams,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Menu,
        listFilter: false,
        availableFilter: false,
        relatedEntityType: relatedEntityType
      }

      let result = await menuService.getSummaryView(teamAsk);

      this.teams = {
        totalCount: 0,
        items: []
      }

      this.teamListSummary = {
        totalCount: 0,
        items: []
      }

      this.defaultTeamList = [];
      this.teamListCache = [];
      this.teamsearch_Mentions = [];

      result.teams.map((x: any) => {
        this.teams.items.push(x);
        this.teams.totalCount = this.teams.totalCount + 1;
        this.teamsearch_Mentions.push(x.firstName + ' ' + x.lastName);
        if (mapRecords === true) {

          this.entityReturn.records.map((y: any) => {

            if (y.linkedEntityType === RelatedEntityTypes.Teams && y.linkedEntityID === x.teamID && y.relatedEntityType === RelatedEntityTypes.Record) {
              this.teamListSummary.items.push(x);
              this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
              this.defaultTeamList.push(x);
              this.teamListCache.push(x);
            }

          });

        }

      });

    }
    catch { }
    return;
  }


  @action //Add Notes to Temp List
  async addTeam(team: TeamListSummaryDto, isSecurity: boolean) {

    try {

      let teamCache = team;

      if (isSecurity === true) {
        this.teamListCache_Security.push(teamCache);

        if (teamCache.profileImageID < 0) {
          let url = await this.getProfileImage(teamCache.profileImageID);
          if (url === '_') {
            teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            teamCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.teamListSummary_Security.items.push(teamCache);
        this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount + 1;
      }
      else {
        this.teamListCache.push(teamCache);

        if (teamCache.profileImageID < 0) {
          let url = await this.getProfileImage(teamCache.profileImageID);
          if (url === '_') {
            teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
          }
          else {
            teamCache.profileImageURL = url;
          }
        }

        //Add Note to Summary View
        this.teamListSummary.items.push(teamCache);
        this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
      }

    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeTeam(recordID: number, isSecurity: boolean) {

    try {

      if (isSecurity === true) {
        this.teamListCache_Security = this.teamListCache_Security.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
        this.teamListSummary_Security.items = this.teamListSummary_Security.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
        this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount - 1;
      }
      else {
        this.teamListCache = this.teamListCache.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
        this.teamListSummary.items = this.teamListSummary.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
        this.teamListSummary.totalCount = this.teamListSummary.totalCount - 1;
      }

    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncTeams(entityID: number, entityRecords: any[]) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {

      this.entityCreate.relatedEntityType = RelatedEntityTypes.Record;
      this.entityDelete.relatedEntityType = RelatedEntityTypes.Record;
      var teamAddList = this.teamListCache;

      //Parse default against temp for Deletes 
      this.defaultTeamList.map((x: TeamListSummaryDto) => {

        var containsTeam = false;
        this.teamListCache.map((y: TeamListSummaryDto) => {
          if (x.teamID === y.teamID) { containsTeam = true; }
        })

        if (containsTeam === false) {

          entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Teams && z.LinkedEntityID === x.teamID) {
              this.entityDelete.records.push({
                id: z.id,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Teams,
                linkedEntityID: x.teamID
              })
            }
          })
        }
        else
        {
          teamAddList = teamAddList.filter((a: TeamListSummaryDto) => a.teamID !== x.teamID);
        }

      })

      await menuService.delete(this.entityDelete);

      teamAddList.map((x: any) => {
        if (x.id < 0) {
          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Record,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.Teams,
            linkedEntityID: x.teamID
          })

        }
      });

      await menuService.create(this.entityCreate);

    }
    catch { }
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

    this.multiEntityList = {
      totalCount: 0,
      items: []
    };

    this.entityCreate = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityCreate.maxResultCount = maxResultCount;

    this.entityEdit = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityEdit.maxResultCount = maxResultCount;
    this.entityDelete = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityDelete.maxResultCount = maxResultCount;

    this.entityReturn = {
      name: '',
      value: '',
      description: '',
      id: 0,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    };

    this.entityStates = {
      totalCount: 0,
      items: []
    };

    this.entityItems = {
      totalCount: 0,
      items: []
    };

    this.entityOptions = {
      totalCount: 0,
      items: []
    };

    this.entityNotesCache = [];
    this.entityNoteRecordsCache = [];
    this.defaultEntityNotes = [];

    this.defaultEntityNoteRecords = [];
    this.entityNotesSummary = {
      totalCount: 0,
      items: []
    }

    this.currentNotifications = [{
      id: 0,
      message: 'Offices Closed Monday: 10/10/22',
      type: 'info'
    }];
  }

  @action
  async initFileStores() {

    this.defaultEntityImages = [];
    this.defaultEntityFiles = [];
    this.fileCache =
    {
      entityType: 0,
      entityID: 0,
      fileList: []
    };
  }

  @action
  async initCacheStores() {

    this.valuesCache = [];
    this.rolesSummary = {
      totalCount: 0,
      items: []
    };
    this.templateRecordCache = [];


    this.itemOptionsCache = {
      totalCount: 0,
      items: []
    };

    this.typeItemsCache = {
      totalCount: 0,
      items: []
    };

    this.typeStatesCache = {
      totalCount: 0,
      items: []
    };

    this.typeItemsEdit = {
      totalCount: 0,
      items: []
    };

    this.typeStatesEdit = {
      totalCount: 0,
      items: []
    };

    this.userListCache = [];
    this.defaultUserList = [];
    this.userListSummary = {
      totalCount: 0,
      items: []
    }
    this.userListSummary_Security = {
      totalCount: 0,
      items: []
    }

    this.personListSummary = {
      totalCount: 0,
      items: []
    }
    this.personListSummary_Security = {
      totalCount: 0,
      items: []
    }
    this.teamListSummary = {
      totalCount: 0,
      items: []
    }
    this.teamListSummary_Security = {
      totalCount: 0,
      items: []
    }

    this.entitySummaryList = [];
    this.templateSummaryList = [];
    this.typeSummaryList = [];
    this.fieldSummaryList = [];
    this.peopleSummaryList = [];
    this.teamSummaryList = [];


  }

  @action
  async clearCardData() {

    this.cards_CommentData = [];
    this.cards_BodyData = [];
    this.cards_HeaderData = [];
    this.cards_TagData = [];
    this.cards_BodyConfig = [];
    this.cards_HeaderConfig = [];
    this.cards_TagConfig = [];
  }

  @action
  async getCardData(filter: any) {

    this.cards_CommentData = [];
    this.cards_BodyData = [];
    this.cards_HeaderData = [];
    this.cards_TagData = [];
    this.cards_BodyConfig = [];
    this.cards_HeaderConfig = [];
    this.cards_TagConfig = [];
  }

  //------------------------------------

  @action
  async updateMultiEntityList(updateMemberList: any) {

    this.multiEntityList.items.push(updateMemberList);

  }

  @action
  async deleteMultiEntityList(recordID: number) {
    this.multiEntityList.items = this.multiEntityList.items.filter((x: any) => x !== recordID);
  }


  @action
  async removeMultiEntityDuplicates() {

    let newList: any = [];

    this.multiEntityList.items.map((x: any) => {

      let isDuplicate = false;

      newList.map((y: any) => {
        if (x.value === y.value) {
          isDuplicate = true;
        }
      });

      if (isDuplicate === false) {
        newList.push({ id: 0, value: x.value });
      }

    });

    this.multiEntityList.items = newList;
  }


  @action
  async getEntity(askDto: EntityDto) {
    this.entityID = askDto.id;
    this.entityType = RelatedEntityTypes.Entity;
    this.getFiles();

    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.Entity,
      entityID: askDto.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });


 
  }

  @action
  async createEntity() {
 

    let result = await menuService.create(this.entityCreate);
    this.entityReturn = result;
 


    if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Entity) {

      let tempNotes = this.entityNotesCache;
      let tempNoteRecords = this.entityNoteRecordsCache;
      let entitiesList = this.entityReturn.entities;

      entitiesList.map((x: any) => {
        this.entityNotesCache = tempNotes;
        this.entityNoteRecordsCache = tempNoteRecords;
        this.syncNotes(x.id);
        this.syncUsers(x.id, this.entityReturn.records)
      })


    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Template) {

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.templates[0].id;

      this.initUpdateObject(RelatedEntityTypes.TemplateRecord, itemTypes.Menu, 0, 100);

      //Create Value (Global) Template Records based on the Return as Value Records need to be linked
      result.values.map((valueItem: any) => {

        //valueItem.buttonRecords.map((valueRecord: any) => {
        //});

        valueItem.datePickerRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.datePicker,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        //valueItem.entityGridRecords.map((valueRecord: any) => {
        //});

        //valueItem.entityPickerRecords.map((valueRecord: any) => {
        //});

        //valueItem.fileUploadRecords.map((valueRecord: any) => {
        //});

        //valueItem.imageRecords.map((valueRecord: any) => {
        //});

        valueItem.multiSelectRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.multiSelect,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        valueItem.numberPickerRecords.map((valueRecord: any) => {

          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.numberPicker,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);

        });

        valueItem.paragraphRecords.map((valueRecord: any) => {

          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.paragraph,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);

        });

        //valueItem.profileImageRecords.map((valueRecord: any) => {
        //});

        //valueItem.searchSelectRecords.map((valueRecord: any) => {
        //});

        valueItem.singleSelectRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.singleSelect,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        //valueItem.switchRecords.map((valueRecord: any) => {
        //});

        valueItem.textBoxRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.textBox,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

      })

      // Create Static Field & Per-State (Switch) Template Records based 
      // on Cache as value records don't need to be linked,
      // just the Type Item with the state specified. 
      this.templateRecordCache.map((record: TemplateRecordDto) => {

        let recordUpdate: TemplateRecordDto = {
          id: 0,
          formItemType: record.formItemType,
          templateID: this.fileCache.entityID,
          valueID: record.valueID,
          itemID: record.itemID,
          optionID: record.optionID,
          optionType: record.optionType,
          isActive: record.isActive,
          stateID: record.stateID,
          approvalType: record.approvalType,
          approvalFormat: record.approvalFormat,
          visible: record.visible,
          disabled: record.disabled,
          required: record.required
        }

        this.entityCreate.templateRecords.push(recordUpdate);
      })

      // Create Template Records for Automations, Users, etc. via OptionType & ID
 

      await menuService.create(this.entityCreate);

    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Type) {
      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.types[0].id;
      await menuService.commitEntityFiles(this.fileCache);

      this.initUpdateObject(RelatedEntityTypes.Record, itemTypes.Menu, 0, 100);

      //Sync Workflow Steps
      this.typeStatesEdit.items.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Type,
          relatedEntityID: result.types[0].id,
          linkedEntityType: RelatedEntityTypes.State,
          linkedEntityID: x.id
        });
      })

      //Sync Items
      this.typeItemsEdit.items.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Type,
          relatedEntityID: result.types[0].id,
          linkedEntityType: RelatedEntityTypes.Item,
          linkedEntityID: x.id
        });
      })


      await menuService.create(this.entityCreate);


    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Item) {

      let entityRecords = this.entityCreate.items[0].relatedOptions;
      this.initUpdateObject(RelatedEntityTypes.Record, itemTypes.Menu, 0, 100);

      entityRecords.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Item,
          relatedEntityID: result.items[0].id,
          linkedEntityType: RelatedEntityTypes.Option,
          linkedEntityID: x
        });

      });

      await menuService.create(this.entityCreate);

    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Option) {



    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.State) {



    }


  }

  @action
  async updateEntity() {

    let result = await menuService.update(this.entityCreate);
    this.entityReturn = result;

    let entityRecords = this.entityReturn.records;
    this.entityCreate.records = [];

    if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Entity) {
      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.entities[0].id;
      await menuService.commitEntityFiles(this.fileCache);
      await this.syncNotes(this.entityReturn.entities[0].id);
      await this.syncUsers(this.entityReturn.entities[0].id, entityRecords);



    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Template) {
      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.templates[0].id;
      await menuService.commitEntityFiles(this.fileCache);


    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Type) {

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.types[0].id;
      await menuService.commitEntityFiles(this.fileCache);

      let entityID = this.entityReturn.types[0].id;
      this.entityCreate.relatedEntityType = RelatedEntityTypes.Record;
      this.entityCreate.types = [];
      this.entityCreate.records = [];

      //Determine if Cache Deosn't Contain Edit Item, Then Delete

      this.typeItemsEdit.items.map((x: any) => {

        var containsItem = false;
        this.typeItemsCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        //If Cache doesnt contain edit item
        if (containsItem === false) {

          //Delete Edit Item
          let deleteID = 0;
          entityRecords.map((z: any) => {
            if (z.LinkedEntityID === x.id) {
              deleteID = z.id;
            }
          })

          this.entityCreate.records.push({
            id: deleteID,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.Item,
            linkedEntityID: x.id
          });
        }
      });

      this.typeStatesCache.items.map((x: any) => {

        var containsItem = false;
        this.typeStatesEdit.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          let deleteID = 0;
          entityRecords.map((z: any) => {
            if (z.LinkedEntityID === x.id) {
              deleteID = z.id;
            }
          })

          this.entityCreate.records.push({
            id: deleteID,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.State,
            linkedEntityID: x.id
          });
        }
      });

      if (this.entityCreate.records.length > 0) {
        await menuService.delete(this.entityCreate);
      }

      this.entityCreate.records = [];

      this.typeItemsEdit.items.map((x: any) => {
        var containsItem = false;
        this.typeItemsCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.Item,
            linkedEntityID: x.id
          });

        }
      })

      this.typeStatesEdit.items.map((x: any) => {
        var containsItem = false;
        this.typeStatesCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.State,
            linkedEntityID: x.id
          });

        }
      })


      if (this.entityCreate.records.length > 0) {
        await menuService.create(this.entityCreate);
      }

    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Option) {



    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.State) {



    }

  }


  @action
  async deleteEntity(input: EntityDto) {

    this.entitySummary.items = this.entitySummary.items.filter((x: any) => x.id !== input.id);


    this.entityDelete.entities.push({
      id: input.id,
      name: '',
      title: '',
      templateID: 0,
      typeID: 0,
      currentState: 0,
      externalID: 0,
      isActive: true,
      creatorID: 0,
      profileImageID: 0,
      identifier: 0,
      relatedStatesVerbose: [],
      relatedItemsVerbose: [],
      values: [],
      
      owner: 0,
      ownerType: 0,
      dependant: 0,
      dependantType: 0
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Entity;
    this.entityDelete.itemType = itemTypes.Menu;

    await menuService.delete(this.entityDelete);
  };

  @action
  async unDeleteEntity() {
    await this.initUpdateObject(RelatedEntityTypes.Entity, itemTypes.Menu, 0, 100);
    this.entityCreate.entities.push(this.entityReturn.entities[0]);
    await this.createEntity();
  }

  //--------------------------------------------------------------------------



  @action //gets Notes for initial list
  async getNotes(entityID: number) {

    try {

      var noteAsk = {
        entityType: RelatedEntityTypes.Note,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Menu,
        listFilter: false,
        availableFilter: false,
      }
 
      let result = await menuService.getNotes(noteAsk); 
  
      result.notes.map((x: any) => { 
  
        this.entityReturn.notes.push(x);

      })
 
    }
    catch { }
    return;
  }


  @action //Add Notes to Temp List
  async addNote(note: NoteSummaryDto) {

    try {

      let noteCache = note;

      var noteItem: NoteDto = {
        id: noteCache.id,
        title: noteCache.title,
        type: noteCache.type,
        relatedEntity: noteCache.relatedEntity,
        replyID: noteCache.replyID,
        customColor: noteCache.customColor,
        created: noteCache.created,
        body: noteCache.body,
        isPrivate: noteCache.isPrivate,
        notifyTagged: noteCache.notifyTagged,
        notifyAll: noteCache.notifyAll
      }

      this.entityNotesCache.push(noteItem);

      noteCache.noteRecords.map((y: NoteRecordDto) => {
        var recordItem: NoteRecordDto = {
          id: y.id,
          noteID: y.noteID,
          relatedUser: y.relatedUser,
          relatedEntity: y.relatedEntity
        }

        this.entityNoteRecordsCache.push(recordItem);

      })

      if (note.id < 0) {
        let url = await this.getProfileImage(note.currentUserImageID);
        if (url === '_') {
          noteCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          noteCache.profileImageURL = url;
        }
      }
      //Add Note to Summary View
      this.entityNotesSummary.items.push(noteCache);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount + 1;
    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeNote(recordID: number) {

    try {
      this.entityNotesCache = this.entityNotesCache.filter((x: NoteDto) => x.id !== recordID);
      this.entityNoteRecordsCache = this.entityNoteRecordsCache.filter((x: NoteRecordDto) => x.noteID !== recordID);
      this.entityNotesSummary.items = this.entityNotesSummary.items.filter((x: NoteSummaryDto) => x.id !== recordID);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount - 1;
    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncNotes(entityID: number) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {
 
      this.entityDelete = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.Menu,  
        entities: [],
        options: [],
        items: [],
        states: [],
        types: [],
        records: [],
        templates: [],
        values: [],
        notes: [],
        noteRecords: [], 
        templateRecords: [],
        skipCount: 0,
        maxResultCount: 100
      }

      this.entityEdit = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.Menu,  
        entities: [],
        options: [],
        items: [],
        states: [],
        types: [],
        records: [],
        templates: [],
        values: [],
        notes: [],
        noteRecords: [], 
        templateRecords: [],
        skipCount: 0,
        maxResultCount: 100
      }
 
      //Parse default against temp for Deletes 
      this.defaultEntityNotes.map((x: any) => {

        var containsNote = false;
        this.entityNotesCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityDelete.notes.push(updateItem);
        }

      })
 
      this.defaultEntityNoteRecords.map((x: any) => {

        var containsNote = false;
        this.entityNoteRecordsCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityDelete.noteRecords.push(updateItem);
        }

      })
 
      await menuService.deleteNotes(this.entityDelete);
 
      this.entityNotesCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.id = 0;
          updateItem.relatedEntity = entityID;
          this.entityEdit.notes.push(updateItem);

        }
      });
 
      this.entityNoteRecordsCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityEdit.noteRecords.push(updateItem);
        }
      });
 
      
      await menuService.addNotes(this.entityEdit);
 

    }
    catch { }
    return;
  }


  //--------------------------------------------------------------------------
  @action
  async getEntitySummary(keyword: any) {


    //Get all three summaries at once and switch via Tab View
    //------------------------------------------------------------------
    // Get Standard List
    //------------------------------------------------------------------

    this.entitySummary =
    {
      totalCount: 0,
      items: []
    }

    this.entitySummaryCondensed =
    {
      totalCount: 0,
      items: []
    }

    let entitySummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });

    if (entitySummary.entities.length === 0) {
      this.entitySummary =
      {
        totalCount: -1,
        items: entitySummary.entities
      }
    }
    else {
      this.entitySummary =
      {
        totalCount: entitySummary.entities.length,
        items: entitySummary.entities
      }
    }

    //------------------------------------------------------------------
    // Format the Above for Summary View
    //------------------------------------------------------------------

    //Get List of Unique Template IDs
    let templateIDList: number[] = [-1];
    this.entitysearch_Hashtags = [];

    this.entitySummary.items.map((x: any) => {

      //------------------------------------------------------------------
      // Set DataView Table Data   
      //------------------------------------------------------------------
      var entity_dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "identifier": x.identifier === undefined ? 'Unknown' : x.identifier,
        "currentState": x.currentState === undefined ? 'Unknown' : x.currentState,
        "templateID": x.templateID === undefined ? 'Unknown' : x.templateID,
        "creatorID": x.creatorID === undefined ? 'Unknown' : x.creatorID,
      }

      this.entity_dataExportTableData.push(entity_dataItem);
      this.entityCondensed_dataExportTableData.push(entity_dataItem);


      //------------------------------------------------------------------
      // Get uniquie list
      //------------------------------------------------------------------

      let containsID: boolean = false;
      templateIDList.map((y: any) => {
        if (x.templateID === y) {
          containsID = true;
        }
      })

      if (containsID === false) {
        templateIDList.push(x.templateID);
      }
    })

    //Loop Unique IDs & Sub-Loop Fitered List of Items
    templateIDList.filter((tx: any) => tx > 0).map((x: any) => {

      let filteredItems = this.entitySummary.items.filter((xt: any) => xt.templateID === x);

      let summaryAdd = {
        id: x,
        name: filteredItems[0].name,
        title: filteredItems[0].title,
        templateID: filteredItems[0].templateID,
        typeID: filteredItems[0].typeID,
        currentState: filteredItems[0].currentState,
        isActive: filteredItems[0].isActive,
        creatorID: filteredItems[0].creatorID,
        profileImageID: filteredItems[0].profileImageID,
        identifier: filteredItems[0].identifier,
        relatedStatesVerbose: filteredItems[0].relatedStatesVerbose,
        relatedItemsVerbose: filteredItems[0].relatedItemsVerbose,
      }

      this.entitySummaryCondensed.items.push(summaryAdd);

    })

    //------------------------------------------------------------------
    // Run the 'Get Activity' Function  
    //------------------------------------------------------------------

    await this.getActionSummary(itemTypes.Menu, itemTypes.Menu, -1)

    //------------------------------------------------------------------
    // Refresh DataView Table Data  
    //------------------------------------------------------------------

    this.entity_dataExportTableData = [];
    this.entityCondensed_dataExportTableData = [];

    this.entity_dataExportTable = {
      fields: this.entity_dataTableFields,
      "meta": this.entity_dataTableMeta,
      "data": this.entity_dataExportTableData,
      "totalData": this.entity_dataExportTableTotalData,
    }
    this.entityCondensed_dataExportTable = {
      fields: this.entityCondensed_dataTableFields,
      "meta": this.entityCondensed_dataTableMeta,
      "data": this.entityCondensed_dataExportTableData,
      "totalData": this.entityCondensed_dataExportTableTotalData,
    }
  }

  @action
  async getOptionSummary(keyword: any) {

    this.entityOptionSummary =
    {
      totalCount: 0,
      items: []
    }

    this.option_dataExportTableData = [];
    this.option_dataExportTableTotalData = [];

    let optionSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });

    if (optionSummary !== undefined) {
      this.entityOptions =
      {
        totalCount: optionSummary.options.length,
        items: optionSummary.options
      }
    }

    // Loop Retrieved States
    optionSummary.options.map((x: any) => {
      let resultString = x.name === undefined ? '' : x.name;

      // Get Data for Mentions
      if (resultString !== '' && this.optionsearch_Mentions.includes(resultString) === false) {
        this.optionsearch_Mentions.push(resultString);
      }

      // Get Data for Hashtags
      if (resultString !== '' && this.optionsearch_Hashtags.includes(resultString) === false) {
        this.optionsearch_Hashtags.push(resultString);
      }

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "optionType": x.optionType === undefined ? 'Unknown' : x.optionType,
        "boundState": x.boundState === undefined ? 'Unknown' : x.boundState,
        "boundRole": x.boundRole === undefined ? 'Unknown' : x.boundRole,
        "boundPerson": x.boundPerson === undefined ? 'Unknown' : x.boundPerson,
        "boundTeam": x.boundTeam === undefined ? 'Unknown' : x.boundTeam,
        "inputType": x.inputType === undefined ? 'Unknown' : x.inputType,

      }



      return this.option_dataExportTableData.push(dataItem);

    });

    this.option_dataExportTable = {
      fields: this.option_dataTableFields,
      "meta": this.option_dataTableMeta,
      "data": this.option_dataExportTableData,
      "totalData": this.option_dataExportTableTotalData,
    }

    if (optionSummary.options.length === 0) {
      this.entityOptionSummary =
      {
        totalCount: -1,
        items: optionSummary.options
      }
    }
    else {
      this.entityOptionSummary =
      {
        totalCount: optionSummary.options.length,
        items: optionSummary.options
      }
    }
  }

  @action
  async getFileDownload(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await menuService.getImage(recordID);

      return result;
    }
  }


  @action
  async getAllOptions() {

    this.entityOptions = {
      totalCount: 0,
      items: []
    }

    let result = await menuService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

    this.optionsList =
    {
      totalCount: result.options.length,
      items: result.options
    }

  }

  @action
  async updateOptionsList(updateMemberList: any) {
    this.entityOptions.items.push(updateMemberList);
  }

  @action
  async deleteOptionsMember(recordID: number) {
    this.entityOptions.items = this.entityOptions.items.filter((x: UpdateMenuOptionList) => x.id !== recordID);

  }

  @action
  async deleteOption(input: EntityDto) {

    this.entityOptionSummary.items = this.entityOptionSummary.items.filter((x: any) => x.id !== input.id);

    await this.getOption(input);

    this.entityDelete.options.push({
      id: input.id,
      name: '',
      title: '',
      profileImageID: 0,
      optionType: 0,
      boundPerson: 0,
      boundState: 0,
      boundRole: 0,
      boundTeam: 0,
      action: 0,
      module: 0,
      sourceType: 0,
      sourceItem: 0,
      targetType: 0,
      targetItem: 0,
      valueRules: 0,

      stageCondition: 0,
      newStage: 0,
      sourceStage: 0,
      stageConditionValue: 0
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Option;
    this.entityDelete.itemType = itemTypes.Menu;

    await menuService.delete(this.entityDelete);
    this.getAllOptions();
  }

  @action
  async unDeleteOption() {
    await this.initUpdateObject(RelatedEntityTypes.Option, itemTypes.Menu, 0, 100);
    this.entityCreate.options.push(this.entityReturn.options[0]);
    await this.createEntity();
  }

  @action
  async getOption(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Option;

    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

  }

  //------------------------------------

  @action
  async getAllTypes() {

    this.entityTypes = {
      totalCount: 0,
      items: []
    }

    let result = await menuService.get({
      entityType: RelatedEntityTypes.Type,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });



    this.typesList =
    {
      totalCount: result.types.length,
      items: result.types
    }

  }

  @action
  async getTypeSummary(keyword: any) {

    this.entityTypeSummary =
    {
      totalCount: 0,
      items: []
    }

    this.type_dataExportTableData = [];
    this.type_dataExportTableTotalData = [];

    let typeSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Type,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });
    if (typeSummary !== undefined) {
      this.entityTypes =
      {
        totalCount: typeSummary.types.length,
        items: typeSummary.types
      }
    }

    // Loop Retrieved States
    typeSummary.types.map((x: any) => {
      let resultString = x.name === undefined ? '' : x.name;

      // Get Data for Mentions
      if (resultString !== '' && this.typesearch_Mentions.includes(resultString) === false) {
        this.typesearch_Mentions.push(resultString);
      }

      // Get Data for Hashtags
      if (resultString !== '' && this.typesearch_Hashtags.includes(resultString) === false) {
        this.typesearch_Hashtags.push(resultString);
      }

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,

      }



      return this.type_dataExportTableData.push(dataItem);

    });

    this.type_dataExportTable = {
      fields: this.type_dataTableFields,
      "meta": this.type_dataTableMeta,
      "data": this.type_dataExportTableData,
      "totalData": this.type_dataExportTableTotalData,
    }



    if (typeSummary.options.length === 0) {
      this.entityTypeSummary =
      {
        totalCount: -1,
        items: typeSummary.types
      }
    }
    else {
      this.entityTypeSummary =
      {
        totalCount: typeSummary.types.length,
        items: typeSummary.types
      }
    }


  }


  @action
  async updateTypesList(updateMemberList: UpdateMenuTypeList) {
    this.entityTypes.items.push(updateMemberList);
  }

  @action
  async deleteTypesMember(recordID: number) {
    this.entityTypes.items = this.entityTypes.items.filter((x: UpdateMenuTypeList) => x.id !== recordID);
  }

  @action
  async deleteType(input: EntityDto) {
    this.entityTypeSummary.items = this.entityTypeSummary.items.filter((x: any) => x.id !== input.id);
    //await this.getType(input); 

    this.entityDelete.types.push({
      id: input.id,
      name: '',
      title: '',
      relatedStates: [],
      relatedItems: [],
      profileImageID: 0,
      relatedItemsVerbose: [],
      relatedStatesVerbose: []

    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Type;
    this.entityDelete.itemType = itemTypes.Menu;
    await menuService.delete(this.entityDelete);
    this.getAllTypes();
  }

  @action
  async unDeleteType() {
    await this.initUpdateObject(RelatedEntityTypes.Type, itemTypes.Menu, 0, 100);
    this.entityCreate.types.push(this.entityReturn.types[0]);
    await this.createEntity();
  }

  @action
  async getType(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Type;
    this.getFiles();

    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.Type,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });


  }

  async getTypeOptions() {

    //Set States
    this.entityItems.items = this.entityReturn.types[0].relatedItemsVerbose;
    this.entityItems.totalCount = this.entityStates.items.length;

    //Set Items
    this.entityStates.items = this.entityReturn.types[0].relatedStatesVerbose;
    this.entityStates.totalCount = this.entityStates.items.length;

  }

  //------------------------------------

  @action
  async getAllTemplates() {

    this.templateItems = {
      totalCount: 0,
      items: []
    }

    let result = await menuService.get({
      entityType: RelatedEntityTypes.Template,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });



    this.templatesList =
    {
      totalCount: result.templates.length,
      items: result.templates
    }

  }

  @action
  async getTemplateSummary(keyword: any) {

    this.entityTemplateSummary =
    {
      totalCount: 0,
      items: []
    }

    this.template_dataExportTableData = [];
    this.template_dataExportTableTotalData = [];

    let templateSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Template,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });

    let typeSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Type,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });


    typeSummary.types.map((typeName: any) => {
      let resultString = typeName.name;

      // Get Data for Mentions
      if (resultString !== '' && this.templatesearch_Mentions.includes(resultString) === false) {
        this.templatesearch_Mentions.push(resultString);
      }

      // Get Data for Hashtags
      if (resultString !== '' && this.templatesearch_Hashtags.includes(resultString) === false) {
        this.templatesearch_Hashtags.push(resultString);
      }

    })
    if (templateSummary !== undefined) {
      this.entityTemplates =
      {
        totalCount: templateSummary.templates.length,
        items: templateSummary.templates
      }
    }

    // Loop Retrieved States
    templateSummary.templates.map((x: any) => {

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,

      }



      return this.template_dataExportTableData.push(dataItem);

    });

    this.template_dataExportTable = {
      fields: this.template_dataTableFields,
      "meta": this.template_dataTableMeta,
      "data": this.template_dataExportTableData,
      "totalData": this.template_dataExportTableTotalData,
    }


    if (templateSummary.templates.length === 0) {
      this.entityTemplateSummary =
      {
        totalCount: -1,
        items: templateSummary.templates
      }
    }
    else {
      this.entityTemplateSummary =
      {
        totalCount: templateSummary.templates.length,
        items: templateSummary.templates
      }
    }


  }


  @action
  async getTemplateItemSummary(keyword: any) {

    this.templateItems =
    {
      totalCount: 0,
      items: []
    }
  }

  @action
  async updateTemplatesList(updateMemberList: UpdateMenuTemplateList) {
    this.entityTemplates.items.push(updateMemberList);
  }

  @action
  async deleteTemplatesMember(recordID: number) {
    this.entityTemplates.items = this.entityTemplates.items.filter((x: UpdateMenuTemplateList) => x.id !== recordID);
  }

  @action
  async deleteTemplate(input: EntityDto) {
    this.entityTemplateSummary.items = this.entityTemplateSummary.items.filter((x: any) => x.id !== input.id);

    await this.getTemplate(input);

    this.entityDelete.templates.push({
      id: input.id,
      name: '',
      title: '',
      typeID: 0,
      profileImageID: 0,
      defaultState: 0,
      globalSecurity: false,
      rolesRequired: false,
      limitedScope: false,
      relatedValues: []

    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Template;
    this.entityDelete.itemType = itemTypes.Menu;
    await menuService.delete(this.entityDelete);
    this.getAllItems();
  }

  @action
  async unDeleteTemplate() {
    await this.initUpdateObject(RelatedEntityTypes.Template, itemTypes.Menu, 0, 100);
    this.entityCreate.templates.push(this.entityReturn.templates[0]);
    await this.createEntity();
  }

  @action
  async getTemplate(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Template;

    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.Template,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });


  }

  @action
  async updateTemplateRecordList(updateMemberList: any) {
    this.templateRecordCache.push(updateMemberList);
  }

  @action
  async deleteTemplateRecordMember(recordID: number) {
    this.templateRecordCache = this.templateRecordCache.filter((x: TemplateRecordDto) => x.id !== recordID);
  }

  @action
  async updateValueList(updateMemberList: any) {
    this.valuesCache.push(updateMemberList);
  }

  @action
  async deleteValueMember(recordID: any) {
    this.valuesCache = this.valuesCache.filter((x: any) => x.id !== recordID);
  }


  //------------------------------------

  @action
  async getAllStates() {

    this.entityStates = {
      totalCount: 0,
      items: []
    }

    let result = await menuService.get({
      entityType: RelatedEntityTypes.State,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

    this.statesList =
    {
      totalCount: result.states.length,
      items: result.states
    }

  }

  @action
  async getStateSummary(keyword: any) {

    this.entityStateSummary =
    {
      totalCount: 0,
      items: []
    }

    this.state_dataExportTableData = [];
    this.state_dataExportTableTotalData = [];


    let stateSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.State,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });

    if (stateSummary !== undefined) {
      this.entityStates =
      {
        totalCount: stateSummary.states.length,
        items: stateSummary.states
      }
    }

    // Loop Retrieved States
    stateSummary.states.map((x: any) => {



      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "displayType": x.displayType === undefined ? 'Unknown' : x.displayType,
        "forwardText": x.forwardText === undefined ? 'Unknown' : x.forwardText,
        "revertText": x.revertText === undefined ? 'Unknown' : x.revertText,
        "allowSkipTo": x.allowSkipTo === undefined ? 'Unknown' : x.allowSkipTo,
        "allowRevert": x.allowRevert === undefined ? 'Unknown' : x.allowRevert,
        "nextActionOnly": x.nextActionOnly === undefined ? 'Unknown' : x.nextActionOnly,
        "lastActionOnly": x.lastActionOnly === undefined ? 'Unknown' : x.title,
        "noActions": x.noActions === undefined ? 'Unknown' : x.noActions,
      }



      return this.state_dataExportTableData.push(dataItem);

    });

    this.state_dataExportTable = {
      fields: this.state_dataTableFields,
      "meta": this.state_dataTableMeta,
      "data": this.state_dataExportTableData,
      "totalData": this.state_dataExportTableTotalData,
    }


    if (stateSummary.states.length === 0) {
      this.entityStateSummary =
      {
        totalCount: -1,
        items: stateSummary.states
      }
    }
    else {
      this.entityStateSummary =
      {
        totalCount: stateSummary.states.length,
        items: stateSummary.states
      }
    }
  }

  @action
  async updateStatesList(updateMemberList: UpdateMenuStateList) {
    this.typeStatesEdit.items.push(updateMemberList);
  }

  @action
  async deleteStatesMember(recordID: number) {
    this.typeStatesEdit.items = this.typeStatesEdit.items.filter((x: UpdateMenuStateList) => x.id !== recordID);
  }

  @action
  async deleteState(input: EntityDto) {
    this.entityStateSummary.items = this.entityStateSummary.items.filter((x: any) => x.id !== input.id);
    await this.getState(input);
    this.entityDelete.states.push(
      {
        id: input.id,
        name: '',
        title: '',
        profileImageID: 0,
        displayType: 0,
        forwardText: '',
        revertText: '',
        restricted: false,
        allowSkipTo: false,
        allowRevert: false,
        nextActionOnly: false,
        lastActionOnly: false,
        noActions: false,
        type: 0
      }
    );

    this.entityDelete.relatedEntityType = RelatedEntityTypes.State;
    this.entityDelete.itemType = itemTypes.Menu;

    await menuService.delete(this.entityDelete);
    this.getAllStates();
  }

  @action
  async unDeleteState() {
    await this.initUpdateObject(RelatedEntityTypes.State, itemTypes.Menu, 0, 100);
    this.entityCreate.states.push(this.entityReturn.states[0]);
    await this.createEntity();
  }

  @action
  async getState(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.State;

    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.State,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

  }

  //------------------------------------

  @action
  async getAllItems() {
    this.entityItems = {
      totalCount: 0,
      items: []
    }

    let result = await menuService.get({
      entityType: RelatedEntityTypes.Item,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });


    this.itemsList =
    {
      totalCount: result.items.length,
      items: result.items
    }

  }

  @action
  async getItemSummary(keyword: any) {

    this.entityItemSummary =
    {
      totalCount: 0,
      items: []
    }

    this.item_dataExportTableData = [];
    this.item_dataExportTableTotalData = [];

    let itemSummary = await menuService.getSummaryView({
      entityType: RelatedEntityTypes.Item,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
    });

    if (itemSummary !== undefined) {
      this.entityItems =
      {
        totalCount: itemSummary.items.length,
        items: itemSummary.items
      }
    }

    // Loop Retrieved States
    itemSummary.items.map((x: any) => {
      let resultString = x.name === undefined ? '' : x.name;


      // Get Data for Mentions
      if (resultString !== '' && this.itemsearch_Mentions.includes(resultString) === false) {
        this.itemsearch_Mentions.push(resultString);
      }

      // Get Data for Hashtags
      if (resultString !== '' && this.itemsearch_Hashtags.includes(resultString) === false) {
        this.itemsearch_Hashtags.push(resultString);
      }

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "itemType": x.itemType === undefined ? 'Unknown' : x.itemType,
        "inputType": x.inputType === undefined ? 'Unknown' : x.inputType,
        "optionType": x.optionType === undefined ? 'Unknown' : x.optionType,
        "selectionType": x.selectionType === undefined ? 'Unknown' : x.selectionType,
      }



      return this.item_dataExportTableData.push(dataItem);

    });

    this.item_dataExportTable = {
      fields: this.item_dataTableFields,
      "meta": this.item_dataTableMeta,
      "data": this.item_dataExportTableData,
      "totalData": this.item_dataExportTableTotalData,
    }


    if (itemSummary.items.length === 0) {
      this.entityItemSummary =
      {
        totalCount: -1,
        items: itemSummary.items
      }
    }
    else {
      this.entityItemSummary =
      {
        totalCount: itemSummary.items.length,
        items: itemSummary.items
      }
    }

  }

  @action
  async updateItemsList(updateMemberList: any) {
    this.typeItemsEdit.items.push(updateMemberList);
  }

  @action
  async deleteItemsMember(recordID: number) {
    this.typeItemsEdit.items = this.typeItemsEdit.items.filter((x: UpdateMenuItemList) => x.id !== recordID);
  }

  @action
  async deleteItem(input: EntityDto) {
    this.entityItemSummary.items = this.entityItemSummary.items.filter((x: any) => x.id !== input.id);


    await this.getItem(input);
    this.entityDelete.items.push({
      id: input.id,
      name: '',
      title: '',
      profileImageID: 0,
      itemType: 0,
      inputType: 0,
      relatedOptions: [],
      relatedOptionsVerbose: [],
      optionType: 0,
      selectionType: 0,
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Item;
    this.entityDelete.itemType = itemTypes.Menu;

    await menuService.delete(this.entityDelete);
    this.getAllItems();
  }

  @action
  async unDeleteItem() {
    await this.initUpdateObject(RelatedEntityTypes.Item, itemTypes.Menu, 0, 100);
    this.entityCreate.items.push(this.entityReturn.items[0]);
    await this.createEntity();
  }


  @action
  async getItem(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Item;

    //Get Core Item
    this.entityReturn = await menuService.get({
      entityType: RelatedEntityTypes.Item,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
    });

    this.itemOptionsCache = {
      totalCount: 0,
      items: this.entityReturn.items[0].relatedOptionsVerbose
    }

  }

  async getItemOptions() {
    //Set Options
    this.entityOptions.items = this.entityReturn.items[0].relatedOptionsVerbose;
    this.entityOptions.totalCount = this.entityOptions.items.length;
  }

  //--------------------------------------------------------------------------

  //Get Action Summary
  @action
  async getActionSummary(listType: number, moduleFilter: number = -1, entityFilter: number = -1) {

    try {

      var actionAsk = {
        entityType: listType,
        relatedEntityType: moduleFilter,
        entityID: entityFilter,
        filter: '',
        moduleType: moduleFilter,
        listFilter: false,
        availableFilter: false
      }

      this.actionSummaryReturn = {
        totalCount: 0,
        items: [],
      }

      let result = await menuService.getActionSummary(actionAsk);


      this.actionSummaryReturn = {
        totalCount: result.totalCount,
        items: result.userActions,
      }

      this.entityActivity_dataExportTableData = [];

      this.actionSummaryReturn.items.map((x: any) => {

        //------------------------------------------------------------------
        // Set DataView Table Data   
        //------------------------------------------------------------------
        var entity_dataItem: Data =
        {
          "id": x.id === undefined ? 0 : x.id,
          "actionType": x.actionType === undefined ? 'Unknown' : x.actionType,
          "actionID": x.actionID === undefined ? 'Unknown' : x.actionID,
          "entityName": x.entityName === undefined ? 'Unknown' : x.entityName,
          "entityID": x.entityID === undefined ? 'Unknown' : x.entityID,
          "previousStateName": x.temppreviousStateNamelateID === undefined ? 'Unknown' : x.previousStateName,
          "previousStateAction": x.previousStateAction === undefined ? 'Unknown' : x.previousStateAction,
          "previousStateID": x.previousStateID === undefined ? 'Unknown' : x.previousStateID,
          "newStateName": x.newStateName === undefined ? 'Unknown' : x.newStateName,
          "newStateAction": x.newStateAction === undefined ? 'Unknown' : x.newStateAction,
          "newStateID": x.newStateID === undefined ? 'Unknown' : x.newStateID,
          "itemName": x.itemName === undefined ? 'Unknown' : x.itemName,
          "itemID": x.itemID === undefined ? 'Unknown' : x.itemID,
          "itemValue": x.itemValue === undefined ? 'Unknown' : x.itemValue,
          "notes": x.notes === undefined ? 'Unknown' : x.notes,
          "externalID": x.externalID === undefined ? 'Unknown' : x.externalID,
          "isRestricted": x.isRestricted === undefined ? 'Unknown' : x.isRestricted,
          "createdTime": x.createdTime === undefined ? 'Unknown' : x.createdTime,
          "creatorID": x.creatorID === undefined ? 'Unknown' : x.creatorID,
          "ownerID": x.ownerID === undefined ? 'Unknown' : x.ownerID,
          "relatedEntityType": x.relatedEntityType === undefined ? 'Unknown' : x.relatedEntityType,
          "relatedEntityID": x.relatedEntityID === undefined ? 'Unknown' : x.relatedEntityID,
          "linkedEntityType": x.linkedEntityType === undefined ? 'Unknown' : x.linkedEntityType,
          "linkedEntityID": x.linkedEntityID === undefined ? 'Unknown' : x.linkedEntityID,
        }

        this.entityActivity_dataExportTableData.push(entity_dataItem);

      });


      this.entityActivity_dataExportTable = {
        fields: this.entityActivity_dataTableFields,
        "meta": this.entityActivity_dataTableMeta,
        "data": this.entityActivity_dataExportTableData,
        "totalData": this.entityActivity_dataExportTableTotalData,
      }

    }
    catch { }
    return;
  }

  @action
  async getExtenalSummary(module: any, entityType: any, target: any = 0) {

    if (module === itemTypes.Communication) {
      if (isGranted('Communication') === true) {

        let entitySummary = await communicationService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }

      }
    }
    else if (module === itemTypes.Integrations) {
      if (isGranted('Integrations') === true) {
        let entitySummary = await integrationService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    } else if (module === itemTypes.Inventory) {
      if (isGranted('Inventory') === true) {
        let entitySummary = await inventoryService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.Payments) {
      if (isGranted('Payments') === true) {
        let entitySummary = await paymentService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.Persons) {
      if (isGranted('People') === true) {
        let entitySummary = await peopleService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          this.peopleSummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.Reports) {
      if (isGranted('Reports') === true) {
        let entitySummary = await reportService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.Schedules) {
      if (isGranted('Scheduling') === true) {
        let entitySummary = await schedulingService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.Teams) {
      if (isGranted('Teams') === true) {
        let entitySummary = await teamService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          this.templateSummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }
    else if (module === itemTypes.WorkOrders) {
      if (isGranted('WorkOrder') === true) {
        let entitySummary = await workOrderService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
      }
    }

    return;
  }

}

export default MenuStore;
