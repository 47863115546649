import { L } from "../../lib/abpUtility";

export const itemTypes = {
    States: -5,
    Options: -4,
    Items: -3,
    Types: -2,
    Templates: -1,

    Communication: 0,
    Persons: 1,
    Teams: 2,
    Actions: 3,
    Assets: 4,
    Inventory: 5,
    Messages: 6,
    Chats: 7,
    Payments: 8,
    Schedules: 9,
    WorkOrders: 10,
    Records: 11,
    External: 12,
    Users: 13,
    Roles: 14,
    Tenants: 15,
    Integrations: 16,
    Reports: 17,
    Menu: 18,
    Configuration: 19,
    InfoHub: 20,
    Administration: 21, 

    Communication_text: L('Communication'),
    Persons_text: L('People'),
    Teams_text: L('Teams'),
    Actions_text: L('Actions'),
    Assets_text: L('Assets'),
    Inventory_text: L('Inventory'),
    Messages_text: L('Messages'),
    Chats_text: L('Chats'),
    Payments_text: L('Payments'),
    Schedules_text: L('Scheduling'),
    WorkOrders_text: L('Work Orders'),
    Records_text: L('Records'),
    External_text: L('External'),
    Users_text: L('Users'),
    Roles_text: L('Roles'),
    Tenants_text: L('Tenants'),
    Integrations_text: L('Integrations'),
    Reports_text: L('Reports'),
    Menu_text: L('Menus'),
    InfoHub_text: L('Info Hub'),
    Administration_text: L('Administration'),

    States_text: L('States'),
    Options_text: L('Options'),
    Items_text: L('Items'),
    Types_text: L('Types'),
    Templates_text: L('Templates'),
    Configuration_text: L('Configuration'),
};
