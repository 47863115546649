import { action, observable } from 'mobx';
import type { CreateOrUpdateUserInput } from '../services/user/dto/createOrUpdateUserInput';
import { EntityDto } from '../services/dto/entityDto';
import { GetRoles } from '../services/user/dto/getRolesOuput';
import { GetUserOutput } from '../services/user/dto/getUserOutput';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import type { CheckPermissionDto } from '../services/user/dto/checkPermissionDto';
import type { PagedUserResultRequestDto } from '../services/user/dto/PagedUserResultRequestDto';
import type { UpdateUserInput } from '../services/user/dto/updateUserInput';
import userService from '../services/user/userService';
import type { RcFile } from 'antd/lib/upload';
import type { EntityCommitContainer, FileUpload } from '../services/dto/fileActionResultDto';
import type { Data, Fields, Meta, S2DataConfig } from '@antv/s2';
import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import type { UploadFile } from 'antd/lib/upload/interface';
import { NoteDto } from '../services/dto/entities/noteDto';
import { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import type { CreateDto } from '../services/dto/createDto';
import { ItemDto } from '../services/dto/entities/itemDto';
import { OptionDto } from '../services/dto/entities/optionDto';
import { RecordDto } from '../services/dto/entities/recordDto';
import { StateDto } from '../services/dto/entities/stateDto';
import { TemplateDto } from '../services/dto/entities/templateDto';
import { TypeDto } from '../services/dto/entities/typeDto';
import type { ReturnDto } from '../services/dto/returnDto';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import { NotificationDto } from '../services/dto/entities/notificationDto';
import { EntitySummaryDto } from '../services/dto/summaries/entitySummaryDto';
import type { UserEntitySummaryDto } from '../services/dto/summaries/userEntitySummaryDto';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto'; 
import { TemplateRecordDto } from '../services/dto/records/templateRecordDto';
import { UserListSummaryDto } from '../services/dto/summaries/userListSummaryDto';
import type { ChangePasswordInput } from '../services/user/dto/changePasswordInput';
import type { ResetPasswordInput } from '../services/user/dto/resetPasswordInput';

class UserStore {
  @observable users!: PagedResultDto<GetUserOutput>;
  @observable editUser!: CreateOrUpdateUserInput;
  @observable roles: GetRoles[] = [];
  @observable assignableRoles: GetRoles[] = [];
  @observable permissionCheckObject!: CheckPermissionDto;
  @observable profileID: number = 0;
  @observable entityID: number = 0;
  @observable entityType: number = 0;
  @observable currentState: number = 0;
  @observable defaultEntityImages!: Array<UploadFile>;
  @observable defaultEntityFiles!: Array<UploadFile>;
  @observable fileCache!: EntityCommitContainer;

  
  @observable users_List!: PagedResultDto<UserListSummaryDto>;
  @observable users_Security!: PagedResultDto<UserListSummaryDto>;
  @observable defaultUserList_Security!: Array<UserListSummaryDto>;
  @observable userListCache_Security!: Array<UserListSummaryDto>;
  @observable userListSummary_Security!: PagedResultDto<UserListSummaryDto>;

  @observable defaultUserList!: Array<UserListSummaryDto>;
  @observable userListCache!: Array<UserListSummaryDto>;
  @observable userListSummary!: PagedResultDto<UserListSummaryDto>;

  @observable userEntitySummaryReturn!: UserEntitySummaryDto<EntitySummaryDto>;
  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

  @observable entityCreate!: CreateDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityEdit!: CreateDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityDelete!: CreateDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityReturn!: ReturnDto<EntityDto, ItemDto, OptionDto, RecordDto, StateDto, TypeDto, TemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;

  @observable defaultEntityNotes!: Array<NoteDto>;
  @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;

  @observable currentNotifications: NotificationDto[] = [];

  @observable entityNotesCache!: Array<NoteDto>;
  @observable entityNoteRecordsCache!: Array<NoteRecordDto>;

  @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

  @observable search_Mentions: string[] = [];
  @observable search_Hashtags: string[] = [];

  @observable dataExportTable!: S2DataConfig;
  @observable dataExportTableData: Data[] = [];
  @observable dataExportTableTotalData: Data[] = [];
  @observable dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['name', 'surname', 'emailAddress', 'isActive', 'currentState'],
  };

  @observable dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "First Name"
    },
    {
      "field": "surname",
      "name": "Last Name"
    },
    {
      "field": "emailAddress",
      "name": "Email"
    },
    {
      "field": "phoneOne",
      "name": "Phone"
    },
    {
      "field": "isActive",
      "name": "Active"
    },
    {
      "field": "currentState",
      "name": "State"
    },
    {
      "field": "addressOne",
      "name": "Address #1"
    },
    {
      "field": "addressTwo",
      "name": "Address #2"
    },
    {
      "field": "city",
      "name": "City"
    },
    {
      "field": "state",
      "name": "State"
    },
    {
      "field": "zip",
      "name": "Zip"
    },
    {
      "field": "phoneTwo",
      "name": "Phone #2"
    },
    {
      "field": "actionRequired",
      "name": "Action Required"
    },
    {
      "field": "accountType",
      "name": "Account Type"
    }
  ];


  @observable userStates: any = {
    totalCount: 2,
    items:
      [
        {
          id: 0,
          name: 'inactive',
          title: 'Inactive',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Activate',
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 1,
          name: 'active',
          title: 'Active',
          profileImageID: 0,
          displayType: 0,
          forwardText: 'Suspend',
          revertText: 'Deactivate',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 2,
          name: 'suspended',
          title: 'Suspended',
          profileImageID: 0,
          displayType: 0,
          forwardText: '',
          revertText: 'Reactivate',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
      ]
  }

  @observable usersearch_Mentions: string[] = [];
  @observable usersearch_Hashtags: string[] =
    ['Online',
      'Available',
      'My Team'];
      
  @observable entityActivity_dataExportTable!: any;
  @observable entityActivity_dataExportTableData: Data[] = [];
  @observable entityActivity_dataExportTableTotalData: Data[] = [];
  @observable entityActivity_dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'actionType', 'entityName'],
  };
  @observable entityActivity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "actionType",
      "name": "Type"
    },
    {
      "field": "actionID",
      "name": "Action ID"
    },
    {
      "field": "entityName",
      "name": "Entity"
    },
    {
      "field": "entityID",
      "name": "Entity ID"
    },
    {
      "field": "previousStateName",
      "name": "Previous State"
    },
    {
      "field": "previousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "previousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "newStateName",
      "name": "New State"
    },
    {
      "field": "newStateAction",
      "name": "New Action"
    },
    {
      "field": "newStateID",
      "name": "New State ID"
    },
    {
      "field": "itemName",
      "name": "Item"
    },
    {
      "field": "itemID",
      "name": "Item ID"
    },
    {
      "field": "itemValue",
      "name": "Item Value"
    },
    {
      "field": "notes",
      "name": "Notes"
    },
    {
      "field": "createdTime",
      "name": "Created"
    },
    {
      "field": "creatorID",
      "name": "Creator"
    },
    {
      "field": "ownerID",
      "name": "Owner"
    },
    {
      "field": "relatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "relatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "linkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "linkedEntityID",
      "name": "Linked Entity ID"
    },
  ];

  //--------------------------------------------------------------------------

  @action //gets Users for initial list
  async getUsers(entityID: number, relatedEntityType: number) {

    try {

      var userAsk = {
        entityType: RelatedEntityTypes.User,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Users,
        listFilter: false,
        availableFilter: false,
        relatedEntityType: relatedEntityType
      }

      let result = await userService.getSummaryView(userAsk);

      this.users_List = {
        totalCount: 0,
        items: []
      }

      this.userListSummary = {
        totalCount: 0,
        items: []
      }

      this.defaultUserList = [];
      this.userListCache = [];
      this.usersearch_Mentions = [];

      result.users.map((x: any) => {
        this.users_List.items.push(x);
        this.users_List.totalCount = this.users_List.totalCount + 1;
        this.usersearch_Mentions.push(x.firstName + ' ' + x.lastName);

        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID) {
            this.userListSummary.items.push(x);
            this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
            this.defaultUserList.push(x);
            this.userListCache.push(x);
          }

        });
      });

    }
    catch { }
    return;
  }

  @action //gets Notes for initial list
  async getNotes(entityID: number) {

    try {

      var noteAsk = {
        entityType: RelatedEntityTypes.Note,
        entityID: entityID,
        filter: '',
        moduleType: itemTypes.Users,
        listFilter: false,
        availableFilter: false,
      }
 
      let result = await userService.getNotes(noteAsk); 
  
      result.notes.map((x: any) => { 
  
        this.entityReturn.notes.push(x);

      })
 
    }
    catch { }
    return;
  }


  @action //Add Notes to Temp List
  async addNote(note: NoteSummaryDto) {

    try {

      let noteCache = note;

      var noteItem: NoteDto = {
        id: noteCache.id,
        title: noteCache.title,
        type: noteCache.type,
        relatedEntity: noteCache.relatedEntity,
        replyID: noteCache.replyID,
        customColor: noteCache.customColor,
        created: noteCache.created,
        body: noteCache.body,
        isPrivate: noteCache.isPrivate,
        notifyTagged: noteCache.notifyTagged,
        notifyAll: noteCache.notifyAll
      }

   
      this.entityNotesCache.push(noteItem);
 

      noteCache.noteRecords.map((y: NoteRecordDto) => {
     
        var recordItem: NoteRecordDto = {
          id: y.id,
          noteID: y.noteID,
          relatedUser: y.relatedUser,
          relatedEntity: y.relatedEntity
        }

        this.entityNoteRecordsCache.push(recordItem);
      
      })

 
      if (note.id < 0) {
        let url = await this.getProfileImage(note.currentUserImageID);
        if (url === '_') {
          noteCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          noteCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.entityNotesSummary.items.push(noteCache);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount + 1;
 
    }
    catch { }
    return;
  }

  @action //Remove Notes from Temp List
  async removeNote(recordID: number) {

    try {
      this.entityNotesCache = this.entityNotesCache.filter((x: NoteDto) => x.id !== recordID);
      this.entityNoteRecordsCache = this.entityNoteRecordsCache.filter((x: NoteRecordDto) => x.noteID !== recordID);
      this.entityNotesSummary.items = this.entityNotesSummary.items.filter((x: NoteSummaryDto) => x.id !== recordID);
      this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount - 1;
    }
    catch { }
    return;
  }

  @action //Sync Notes
  async syncNotes(entityID: number) {

    //NOTE: Temp IDs are in the negatives, set 0 to create

    try {
 
      this.entityDelete = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.Users,  
        entities: [],
        options: [],
        items: [],
        states: [],
        types: [],
        records: [],
        templates: [],
        values: [],
        notes: [],
        noteRecords: [], 
        templateRecords: [],
        skipCount: 0,
        maxResultCount: 100
      }

      this.entityEdit = {
        relatedEntityType: RelatedEntityTypes.Note,
        itemType: itemTypes.Users,  
        entities: [],
        options: [],
        items: [],
        states: [],
        types: [],
        records: [],
        templates: [],
        values: [],
        notes: [],
        noteRecords: [], 
        templateRecords: [],
        skipCount: 0,
        maxResultCount: 100
      }
 
      //Parse default against temp for Deletes 
      this.defaultEntityNotes.map((x: any) => {

        var containsNote = false;
        this.entityNotesCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityDelete.notes.push(updateItem);
        }

      })
 
      this.defaultEntityNoteRecords.map((x: any) => {

        var containsNote = false;
        this.entityNoteRecordsCache.map((y: any) => {
          if (x.id === y.id) { containsNote = true; }
        })

        if (containsNote === false) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityDelete.noteRecords.push(updateItem);
        }

      })
 
      await userService.deleteNotes(this.entityDelete);
 
      this.entityNotesCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.id = 0;
          updateItem.relatedEntity = entityID;
          this.entityEdit.notes.push(updateItem);

        }
      });
 
      this.entityNoteRecordsCache.map((x: any) => {
        if (x.id < 0) {
          var updateItem = x;
          updateItem.relatedEntity = entityID;
          this.entityEdit.noteRecords.push(updateItem);
        }
      });
 
      
      await userService.addNotes(this.entityEdit);
 

    }
    catch { }
    return;
  }

  //--------------------------------------------------------------------------


  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await userService.uploadImage(formData);
    result.uid = result.assetID.toString();
    this.fileCache.fileList.push(result.assetID);

    return result;
  }

  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await userService.uploadImage(formData);

    this.profileID = result.assetID; 

    return result;
  }

  @action
  async getProfileImage(recordID: number) {


    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await userService.getImage(recordID);

      return result.url;
    }
  }

  @action
  async getFileDownload(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await userService.getImage(recordID);

      return result;
    }
  }


  @action //gets files for initial list
  async getFiles(id: number) {

    try {


      let result = await userService.getFiles(id, RelatedEntityTypes.Entity);

      //Add to Default Loads
      result.map((x: FileUpload) => {

        this.defaultEntityFiles.push({
          uid: x.assetID.toString(),
          size: 0,
          name: x.name,
          url: x.url,
          thumbUrl: x.thumbUrl,
          type: x.type,
          status: 'done'

        })

       
        this.fileCache.fileList.push(x.assetID);
      })
    }
    catch { }
    return;
  }

  @action // 
  async removeFileFromList(file: UploadFile) {
    this.fileCache.fileList = this.fileCache.fileList.filter((x: number) => x.toString() !== file.uid);
    return;
  }

  @action
  async initFileStores() {
    this.defaultEntityImages = [];
    this.defaultEntityFiles = [];
    this.fileCache =
    {
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      fileList: []
    };
  }

  @action
  async initUpdateObject() {

    this.entityCreate = {
      relatedEntityType: RelatedEntityTypes.Entity,
      itemType: itemTypes.Users,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    } 

    this.entityEdit = {
      relatedEntityType: RelatedEntityTypes.Entity,
      itemType: itemTypes.Users,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityEdit.maxResultCount = 100;
    this.entityDelete = {
      relatedEntityType: RelatedEntityTypes.Entity,
      itemType: itemTypes.Users,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityReturn = {
      name: '',
      value: '',
      description: '',
      id: 0,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    };

    this.entityNotesCache = [];
    this.entityNoteRecordsCache = [];
    this.defaultEntityNotes = [];

    this.defaultEntityNoteRecords = [];
    this.entityNotesSummary = {
      totalCount: 0,
      items: []
    }


    this.currentNotifications = [];

  }

  //--------------------------------------------------------------------------

  @action
  async create(createUserInput: CreateOrUpdateUserInput) {
    createUserInput.profileImageID = this.profileID; 
    let result = await userService.create(createUserInput);
    this.editUser = result;

    this.fileCache.entityType = RelatedEntityTypes.Entity;
    this.fileCache.entityID = this.editUser.id;

    await userService.commitEntityFiles(this.fileCache);
    await this.syncNotes(this.editUser.id);

    //this.initUpdateObject();
    await this.getAll({ maxResultCount: 200, skipCount: 0, keyword: '' });
  }

  @action
  async update(updateUserInput: UpdateUserInput) {

    updateUserInput.id = this.editUser.id; 
    updateUserInput.profileImageID = this.profileID; 
    let result = await userService.update(updateUserInput);
    this.editUser = result;

    this.fileCache.entityType = RelatedEntityTypes.Entity;
    this.fileCache.entityID = this.editUser.id

    this.fileCache.fileList.map((x: any) => {
      
    })

    await userService.commitEntityFiles(this.fileCache);
    await this.syncNotes(this.editUser.id);

    //this.initUpdateObject();
    await this.getAll({ maxResultCount: 200, skipCount: 0, keyword: '' });
  }

  @action
  async delete(entityDto: EntityDto) {
    await userService.delete(entityDto);
    this.users.items = this.users.items.filter((x: GetUserOutput) => x.id !== entityDto.id);

  }

  //--------------------------------------------------------------------------

  @action
  async getRoles() {
    let result = await userService.getRoles();
    this.roles = result;

    // add roles as hashtags
    result.map((x: GetRoles) => {
      let resultString = x.name === undefined ? '' : x.name;
      if (resultString !== '' && this.search_Hashtags.includes(resultString) === false) {
        return this.search_Hashtags.push(resultString);
      }
      else {
        return;
      }
    });
  }

  @action
  async getAssignableRoles() {
    let result = await userService.getRoles();
    this.assignableRoles = result;
  }

  @action
  async get(entityDto: EntityDto) {
    let result = await userService.get(entityDto);
    this.editUser = result;
  }

  @action
  async getCommentImage(entityDto: EntityDto) {
    let result = await userService.get(entityDto);
    this.editUser = result;

    if (this.editUser.profileImageID === 0 || this.editUser.profileImageID === 1) {
      return DataConsts.defaultProfileImageURL;
    }
    else {
      let resultImg = await userService.getImage(this.editUser.profileImageID);  
      return resultImg.url;
    } 
  }

  @action
  async createUser() {

    await this.initFileStores();


    this.editUser = {
      userName: '',
      orgName: '',
      name: '',
      surname: '',
      emailAddress: '',
      isActive: false,
      roleNames: [],
      password: '',
      id: 0,
      profileImageID: 0,

      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zip: 0,
      phoneOne: '',
      phoneTwo: '',
      showFirstTimeMessage: true,
      showActiveMessage: true,
      actionRequired: false,
      accountType: 0,
      currentState: 0,

      preferredPaymentType: 0,
      storedPaymentOptionID: '' 

    };
    this.roles = [];

    await this.getAssignableRoles();
  }

  @action
  async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto) {

    this.dataExportTableData = [];
    this.dataExportTableTotalData = [];

    let result = await userService.getAll(pagedFilterAndSortedRequest);

    
    if (result !== undefined) {
      this.users =
      {
        totalCount: result.totalCount,
        items: result.items
      }
    }

    // Loop Users
    result.items.map((x: GetUserOutput) => {
      let resultString = x.name === undefined ? '' : x.name;

      // Get Data for Mentions
      if (resultString !== '' && this.search_Mentions.includes(resultString) === false) {
        this.search_Mentions.push(resultString);
      }

      // Get Data for Export Table
      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "surname": x.surname === undefined ? 'Unknown' : x.surname,
        "emailAddress": x.emailAddress === undefined ? 'Unknown' : x.emailAddress,
        "phoneOne": x.phoneOne === undefined ? 'Unknown' : x.phoneOne,
        "currentState": x.currentState === undefined ? 'Unknown' : x.currentState.toString(),
        "addressOne": x.addressOne === undefined ? 'Unknown' : x.addressOne,
        "addressTwo": x.addressTwo === undefined ? 'Unknown' : x.addressTwo,
        "city": x.city === undefined ? 'Unknown' : x.city,
        "state": x.state === undefined ? 'Unknown' : x.state,
        "zip": x.zip === undefined ? 'Unknown' : x.zip,
        "phoneTwo": x.phoneTwo === undefined ? 'Unknown' : x.phoneTwo,
        "actionRequired": x.actionRequired === undefined ? 'Unknown' : x.actionRequired.toString(),
        "accountType": x.accountType === undefined ? 'Unknown' : x.accountType,
        "isActive": x.isActive === undefined ? 'Unknown' : x.isActive.toString()

      }

      return this.dataExportTableData.push(dataItem);

    });

    this.dataExportTable = {
      fields: this.dataTableFields,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    }
  }

  async changeLanguage(languageName: string) {
    await userService.changeLanguage({ languageName: languageName });
  }

  @action
  async changePassword(changePasswordInput: ChangePasswordInput) {
    let result = await userService.changePassword(changePasswordInput);
    return result;
  }

  @action
  async resetPassword(changePasswordInput: ResetPasswordInput) {
    let result = await userService.resetPassword(changePasswordInput);
    return result;
  }


  //--------------------------------------------------------------------------

  //Get User Entity Summary
  @action
  async getUserActivitySummary(entityType: number = -1, entityFilter: string = '') {

    try {

      var activityAsk = {
        entityType: -1,
        relatedEntityType: entityType,
        entityID: 0,
        filter: entityFilter,
        moduleType: -1,
        listFilter: false,
        availableFilter: false
      }

      this.userEntitySummaryReturn = {
        title: '',
        responseType: 0,
        totalCount: 0,
        communication: [],
        integrations: [],
        inventory: [],
        menu: [],
        payments: [],
        people: [],
        teams: [],
        reports: [],
        schedules: [],
        workOrders: [],
      }

      this.userEntitySummaryReturn = await userService.getUserActivitySummary(activityAsk);
  
    }
    catch { }
    return;
  }


  //Get Action Summary
  @action
  async getActionSummary(listType: number, moduleFilter: number = -1, entityFilter: number = -1) {

    try {

      var actionAsk = {
        entityType: listType,
        relatedEntityType: moduleFilter,
        entityID: entityFilter,
        filter: '',
        moduleType: moduleFilter,
        listFilter: false,
        availableFilter: false
      }

      this.actionSummaryReturn = { 
        totalCount: 0,
        items: [], 
      }

      let result = await userService.getActionSummary(actionAsk);

      this.actionSummaryReturn = { 
        totalCount: result.totalCount,
        items: result.userActions, 
      }
 
    }
    catch { }
    return;
  }

}

export default UserStore;

